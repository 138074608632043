.publicBottom{
    background-image: url('./img/backgroundImg.png');
    height:606px;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top:87px;
    .publicBottommain{
        width:1152px;
        margin: 0 auto;
        .publicBottommaintop{
            display: flex;
            justify-content: space-between;
            color: #fff;
            .publicBottommaintoptitle{
                font-size: 16px;
                margin-bottom: 30px;
                line-height: 22px;
                font-weight: bold;
            }
            .publicBottommaintopcontent{
                font-size: 14px;
                font-weight: 300;
                color: #FFFFFF;
                line-height: 20px;
                margin-bottom: 16px;
            }
            .information{
                font-size: 24px;
                font-weight: bold;
            }
            .btn{
                width: 116px;
                height: 36px;
                border-radius: 2px;
                border: 1px solid #FFFFFF;
                background-color: transparent;
                color: #fff;
                cursor: pointer;
            }
        }
        .publicBottommainline{
            width: 1152px;
            height: 1px;
            opacity: 0.2;
            background-color: #fff;
            margin-top: 24px;
            margin-bottom: 39px;
        }
        .publicBottommainname{
            display: flex;
            color: #fff;
            font-size: 14px;
            span{
                margin-right: 27px;
            }
            a{
                color: #fff;
            }
        }
        .publicBottommainaddress{
            display: flex;
            color: #fff;
            font-size: 14px;
            margin-top: 32px;
            >div{
                margin-right: 65px;
            }
        }
    }
}
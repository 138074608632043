.participatemodel{
    width: 100%;
    height:100%;
    background: rgba($color: #000000, $alpha: 0.23);
    position: fixed;
    top:0;
    left: 0;
    .main{
        width: 648px;
        height: 807px;
        background: #FFFFFF;
        border-radius: 3px;
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        .title{
            font-size: 20px;
            font-weight: 400;
            color: #040513;
            line-height: 28px;
            text-align: center;
            padding: 40px 0;
        } 
        .inputitem{
            width: 548px;
            height: 64px;
            background: #FFFFFF;
            border: 1px solid #D0DAF5;
            margin: 0 auto;
            margin-bottom: 30px;
            box-sizing: border-box;
            padding: 22px 18px;
            display: flex;
            align-items: center;
            .iconfont{
                font-size: 20px;
                color: #A3B4D8;
            }
            >input{
                border: none;
                height:28px;
                margin-left: 15px;
                outline: none;
                font-size: 20px;
                width:500px
            }
            >input::placeholder{
                font-size: 20px;
                font-weight: 300;
                color: rgba($color: #040513, $alpha: .5);
                line-height: 28px;
            }
        }
        .btnBox{
            text-align: center;
            padding-top: 30px;
            >button{
                width: 180px;
                height: 60px;
                background: #1A5FFC;
                border-radius: 3px;
                border: none;
                margin-right: 36px;
                color: #fff;
                font-size: 23px;
            }
            >button:nth-child(1){
                color: #1A65FC;
                background:rgba($color:  #1A5FFC, $alpha: .07);
            }
            >button:nth-child(2){
                margin: 0;
            }
        } 
    }

}
.productEntrance{
    background: #FAFCFF;
    .bannerBox{
        background-color: #05132E;
        height:480px;
        .banner{
            position: relative;
            width:1152px;
            margin: auto;
            .content{
                position: absolute;
                top:175px;
                left: 0;
                .title{
                    font-size: 40px;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 56px;
                    margin-bottom: 20px;
                }
                .text{
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 300;
                    color: #FFFFFF;
                    line-height: 28px;
                }
                
            }
        }
    }
    .productEntrancecontent{
        width:1152px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        position: relative;
        top:-50px;
        .productEntrancecontentitem{
            padding-top:51px;
            box-sizing: border-box;
            width: 367px;
            height: 726px;
            background: #FFFFFF;
            border-radius: 4px;
            text-align: center;
            overflow: hidden;
            .title{
                font-size: 32px;
                font-weight: 600;
                color: #040513;
                line-height: 45px;
                margin: 50px 0 24px;
            }
            .main{
                font-size: 22px;
                font-weight: 300;
                color: #040513;
                line-height: 40px;   
                padding-bottom: 53px; 
            }
            .mainin{
                width: 144px;
                height: 144px;
                background: rgba($color: #E4EBFF, $alpha: .3);
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 18px auto;
                border-radius: 50%;
            }
            .code{
                width:181px;
                height:181px;
            }
            .clickItem{
                padding-top: 51px;
                font-size: 16px;
                font-weight: 300;
                color: #1A65FC;
            }
        }
    }
}
.customerplan{
    background: #FAFCFF;
    .banner{
        height:480px;
        background-size: 100% 100%;
        background: linear-gradient(360deg, #F6FAFD 0%, #FDFEFF 100%);
        .bannercenter{
          width:1152px;
          margin: 0 auto;
          height:100%;
          position: relative;
          img{
            display: block;
            height:100%;
            width:100%;
          }
          .bannermain{
            position: absolute;
            top:158px;
            left: 61px;
            .bannermaintop{
                font-size: 40px;
                font-weight: 600;
                color: #040513;
                line-height: 56px;
                margin-bottom: 16px;
            }
            .bannermainmiddle{
                width: 369px;
                font-size: 18px;
                font-weight: 300;
                color: #040513;
                line-height: 28px;
            }
            .bannermainbottom{
              margin-top: 40px;
              display: flex;
              >div{
                cursor: pointer;
              }
              .bannermainbottomleft{
                background-color: #1A5FFC;
                color: #fff;
                width: 116px;
                height: 36px;
                border-radius: 2px;
                margin-right: 17px;
                text-align: center;
                line-height: 36px;
                font-size: 14px;
              }
              .bannermainbottomright{
                width: 116px;
                height: 36px;
                background: #fff;
                border-radius: 2px;
                line-height: 36px;
                font-size: 14px;
                color: #1A5FFC;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
      .rule{
        width: 1152px;
        margin: 0 auto;
        height: 64px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px 0px rgba(111,143,221,0.08);
        border-radius: 4px;
        text-align: center;
        line-height: 61px;
        font-size: 14px;
        color: rgba($color: #040513, $alpha: .7);
        >span{
            color: #FC7C1A;
            font-size: 16px;
            font-weight: 600;
        }
      }
      .texttitle{
        width: 1152px;
        padding: 90px 0 50px;
        margin: 0 auto;
        font-size: 24px;
        font-weight: 600;
        color: #040513;
        line-height: 28px;
      }
      .product{
        width:1152px;
        margin: 0 auto;
        .text{
            font-size: 20px;
            font-weight: 300;
            color: #040513;
            line-height: 20px;
            margin-bottom: 40px;
        }
        .typelist{
            width: 1152px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            position: relative;
            top:-46px;
            .typelistitem{
                width:372px;
                height:517px;
                padding: 0 32px;
                box-sizing: border-box;
                background-color: #fff;
                box-shadow: 0px 2px 8px 0px rgba(111,143,221,0.08);
                position: relative;
                border-radius: 12px;
                >.imgBox{
                    margin: 30px auto;
                    width: 96px;
                    height: 96px;
                    display: block;
                }
                .title{
                    
                    line-height: 69px;
                    font-size: 24px;
                    font-weight: 600;
                    color: #040513;
                    text-align: center;
                    border-bottom: 1px solid #04B593;
                }
                .price{
                    padding-top: 23px;
                    font-size: 44px;
                    font-weight: 600;
                    color: #040513;
                    line-height: 56px;
                    text-align: center;
                    >span{
                        font-size: 20px;
                        font-weight: 600;
                        color: #040513;
                    }
                }
                .user{
                    font-size: 16px;
                    font-weight: 400;
                    color: #040513;
                    line-height: 24px;
                    text-align: center;
                    margin-bottom: 32px;
                }
                >p{
                    vertical-align: middle;
                    padding-left:44px;
                    >img{
                        margin-right: 7px;
                    }
                    font-size: 13px;
                    font-weight: 300;
                    color: rgba($color: #040513, $alpha: .7);
                    line-height: 30px;
                }
                .btn{
                    width: 270px;
                    height: 44px;
                    border-radius: 2px;
                    position: absolute;
                    right: 0;
                    left: 0;
                    bottom:30px;
                    margin: 0 auto;
                    color: #fff;
                    font-size: 14px;
                    line-height: 44px;
                    text-align: center;
                }
            }
            .typelistitem:nth-child(1){
                .title{
                    border-color: #13AEF5;
                }
                .btn{
                    background-color: #13AEF5;
                }
            }
            .typelistitem:nth-child(2){
                .title{
                    border-color: #1A5FFC;
                }
                .btn{
                    background-color: #1A5FFC;
                }
            }
            .typelistitem:nth-child(3){
                .title{
                    border-color: #1A1917;
                }
                >p{
                    padding: 0;
                    text-align: center;
                }
                .btn{
                    background-color: #1A1917;
                }
            }
        }
        .btmBtn{
            text-align: center;
            color: #1A65FC;
            font-size: 24px;
            font-weight: 400;
            line-height: 33px;
            cursor: pointer;
            >i{
                font-size: 24px;
            }
        }
      }
      .detail{
        width: 1152px;
        margin: 0 auto;
        height: 596px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px 0px rgba(111,143,221,0.08);
        border-radius: 4px;
        margin-bottom: 123px;
        box-sizing: border-box;
        padding: 50px 40px;
        .detailitem{
            display: flex;
            margin-bottom: 10px;
            .num{
                width: 26px;
                height: 26px;
                background: #040513;
                border-radius: 2px;
                margin-right: 12px;
                color: #fff;
                font-size: 20px;
                text-align: center;
            }
            p{
                font-size: 20px;
                font-weight: 300;
                color: #040513;
                margin-bottom: 20px;
            }
        }
        .notes{
            padding-top:14px ;
            font-size: 16px;
            font-weight: 300;
            color: #040513;
            line-height: 16px;
        }
        .btn{
            width: 280px;
            height: 64px;
            background: #1A5FFC;
            border-radius: 2px;
            color: #fff;
            font-size: 24px;
            font-weight: 400;
            line-height: 64px;
            cursor: pointer;
            text-align: center;
            margin:0 auto ;
            margin-top: 50px;
        }
      }
}
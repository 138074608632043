.discount{
    background: linear-gradient(180deg, #2970FF 0%, #1843FB 100%, #1843FB 100%);
    .discountmain{
        display: flex;
        align-items: center;
        justify-content: center;
        width:1152px;
        height:140px;
        margin: 0 auto;
        button{
            width: 189px;
            height: 52px;
            background: #FFFCFD;
            border-radius: 2px;
            color: #0934FD;
            border: none;
            font-size: 24px;
            margin-left: 50px;
            cursor: pointer;
        }
    }
}
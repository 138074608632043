@font-face {
  font-family: "iconfont"; /* Project id 3839705 */
  src: url('iconfont.woff2?t=1672888864054') format('woff2'),
       url('iconfont.woff?t=1672888864054') format('woff'),
       url('iconfont.ttf?t=1672888864054') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cha:before {
  content: "\e612";
}

.icon-youjiantou:before {
  content: "\e625";
}

.icon-xingming:before {
  content: "\e62a";
}

.icon-shoujihao:before {
  content: "\e62b";
}

.icon-weixin:before {
  content: "\e856";
}

.icon-xiaolaba:before {
  content: "\e64c";
}

.icon-a-youjiantouzhixiangyoujiantou:before {
  content: "\e60d";
}

.icon-arrow-down:before {
  content: "\e648";
}

.icon-dianhua:before {
  content: "\e61b";
}

.icon-shouhuodizhi:before {
  content: "\e64e";
}

.icon-youjian:before {
  content: "\e624";
}


.fastexperience{
    background-color: #FAFCFF;
    .bannerBox{
        background-color: #040B21;
        height:480px;
        .banner{
            position: relative;
            width:1152px;
            margin:0 auto;
            .content{
                position: absolute;
                top:175px;
                left: 0;
                .title{
                    font-size: 40px;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 56px;
                    margin-bottom: 20px;
                }
                .text{
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-weight: 300;
                    color: #FFFFFF;
                    line-height: 28px;
                }
                
            }
        }
    }
    .main{
        width:1152px;
        margin: 0 auto;
        position: relative;
        top:-70px;
    }
    .serviceBox{
        width: 1152px;
        margin: 0 auto;
        margin-bottom: 100px;
        .service{
            width: 740px;
            height: 234px;
            background: #FFFFFF;
            box-shadow: 0px 10px 24px 0px rgba(8,78,253,0.04);
            border-radius: 3px;
            margin: 0 auto ;
            box-sizing: border-box;
            padding: 40px;
            .servicetop{
                display: flex;
                >div{
                    padding: 14px 0 14px 20px;
                    >p{
                        font-size: 20px;
                        font-weight: 400;
                        color: #040513;
                        line-height: 24px;
                        margin-bottom: 24px;
                    }
                    >div{
                        display: flex;
                        align-items: center;
                        font-size: 32px;
                        font-weight: 400;
                        color: #040513;
                        line-height: 24px;
                        >span{
                            font-size: 16px;
                            font-weight: 300;
                            color: rgba($color: #040513, $alpha: .5);
                            line-height: 24px;
                            margin-right: 16px;
                        }
                    }
                }
            }
            .btnBox{
                text-align: right;
                margin-top: 32px;
                .btn{
                    width: 116px;
                    height: 36px;
                    background: #1A5FFC;
                    border-radius: 2px;
                    display: inline-block;
                    text-align: center;
                    line-height: 36px;
                    color: #fff;
                }
            }
            
        }
    }
}
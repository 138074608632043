.box{
  position: relative;
  background-color: #FAFCFE;
    .banner{
      height:640px;
      background-image: url('./img/bannerback.png');
      background-size: 100% 100%;
      .bannercenter{
        width:1152px;
        margin: 0 auto;
        height:100%;
        position: relative;
        img{
          display: block;
          height:100%
        }
        .bannermain{
          position: absolute;
          top:158px;
          left: 82px;
          .bannermaintop{
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 18px;
            img{
              width:25px;
              height:20px;
              margin-right: 6px;
            }
          }
          .bannermainmiddle{
            color: #fff;
            font-size: 44px;
            line-height: 60px;
            margin-top: 25px;
          }
          .bannermainbottom{
            margin-top: 40px;
            display: flex;
            >div{
              cursor: pointer;
            }
            .bannermainbottomleft{
              background-color: #fff;
              color: #1A65FC;
              width: 116px;
              height: 36px;
              border-radius: 2px;
              margin-right: 17px;
              text-align: center;
              line-height: 36px;
              font-size: 14px;
            }
            .bannermainbottomright{
              width: 144px;
              height: 36px;
              background: rgba($color: #5AE5FF, $alpha: .2);
              border-radius: 2px;
              line-height: 36px;
              font-size: 12px;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              img{
                width:24px;
                height:24px;
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
    }

    .introduce{
      width:1152px;
      height: 358px;
      margin: 0 auto;
      display: flex;
      background-color: #fff;
      box-shadow: 0px 6px 20px 0px rgba(8,78,253,0.06);
      border-radius: 4px;
      position: relative;
      top:-40px;
      align-items: center;
      >.introduceitem{
        width: 289px;
        height: 100%;
        text-align: center;
        padding-top: 34px;
        img{
          width:88px;
          height:88px
        }
        .introducetitle{
          font-size: 20px;
          font-weight: 500;
          color: #040513;
          line-height: 28px;
          margin-top: 21px;
          margin-bottom: 13px;
        }
        >p{
          font-size: 15px;
          font-weight: 300;
          color: rgba($color: #040513, $alpha: .5);
          line-height: 24px;
          margin: 0;
        }
        .introducebottom{
          color: #1A65FC;
          font-size: 14px;
          line-height: 24px;
          margin-top: 18px;
          .introducenum{
            font-size: 28px;
          }
          .introducejia{
            font-size: 24px;
            margin: 0 2px 0 3px;
          }
        }
        
      }
      .introduceitemline{
        width: 1px;
        height: 181px;
        background-color: #D0D9F1;
      }
    }
    .Industrystatus{
      width: 1152px;
      margin: 0 auto;
      .Industrystatustitle{
        text-align: center;
        h2{
          font-size: 26px;
          font-weight: 600;
          color: #040513;
          line-height: 37px;
          margin-bottom: 12px;
        }
        p{
          font-size: 15px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: rgba($color: #040513, $alpha: .5);
          margin: 0;
          margin-bottom: 41px;
        }
      }
      
      .Industrystatustop{
        display: flex;
        background-color: #fff;
        >img{
          width: 921px;
        }
        .Industrystatustopleft{
          flex: 1;
          height:341px;
          padding: 32px 24px;
          .Industrystatustoplefttitle{
            margin-top: 78px;
            font-size: 20px;
            font-weight: 600;
            color: #040513;
            line-height: 28px;
            margin-bottom: 22px;
          }
          >p{
            font-size: 14px;
            font-weight: 300;
            color: rgba($color: #040513, $alpha: .5);
            line-height: 24px;
          }
        }
      }
      .Industrystatusbottom{
        background-color: #fff;
        height:230px;
        display: flex;
        align-items: center;
        .Industrystatusbottomline{
          width: 1px;
          height: 140px;
          background-color: #D0D9F1;
        }
        .Industrystatusbottomitem{
          padding: 17px 23px;
          flex:1;
          .Industrystatusbottomitemtitle{
            font-size: 20px;
            font-weight: 600;
            color: #040513;
            line-height: 28px;
            margin-bottom: 23px;
            margin-top: 33px;
          }
          >p{
            font-size: 14px;
            font-weight: 300;
            color: rgba($color: #040513, $alpha: .5);
            line-height: 24px;
          }
        }
        
      }
    }

    .h2Title{
      text-align: center;
      h2{
        font-size: 26px;
        font-weight: 600;
        color: #040513;
        line-height: 37px;
        margin-bottom: 12px;
        margin-top: 90px;
      }
      p{
        font-size: 15px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: rgba($color: #040513, $alpha: .5);
        margin: 0;
        margin-bottom: 41px;
      }
    }
    .programmeBox{
      background-color: #fff;
    }
    .programme{
      width: 1152px;
      margin: 0 auto;
      .programmemain{
        display: flex;
        height: 690px;
        .rightImg{
          position: relative;
          >img{
            width:921px;
            height:690px;
          }
          .main{
            position: absolute;
            top: 217px;
            left: 81px;
            .title{
              font-size: 32px;
              font-weight: 600;
              color: #040513;
              line-height: 32px;
              margin-bottom: 50px;
            }
            .top{
              margin-bottom: 30px;
              >div{
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 400;
                color: #040513;
                line-height: 16px;
                margin-bottom: 14px;
                >span{
                  width: 5px;
                  height: 5px;
                  background: #A6B3D5;
                  border-radius: 50%;
                  margin-right: 15px;
                }
              }
            }
            .text{
              width: 280px;
              font-size: 14px;
              font-weight: 300;
              color: #040513;
              line-height: 27px;
            }
          }
          .btn{
            cursor: pointer;
            position: absolute;
            top: 559px;
            left: 81px;
            font-size: 16px;
            font-weight: 400;
            color: #3276FF;
            line-height: 26px;
          }
        }
        .leftTab{
          margin-top: 90px;
          >div{
            width: 230px;
            height: 72px;
            display: flex;
            align-items: center;
            font-size: 18px;
            cursor: pointer;
            >.icon-youjiantou {
              flex: 1;
              text-align: right;
              font-size: 28px;
              padding-right: 12px;
              color: rgba($color: #fff, $alpha: .9);
            }
            >img{
              width: 40px;
              height: 40px;
              margin-left: 23px;
            }
          }
          .changeIndex{
            color: #fff;
            background-color: #1A65FC;
          }
        }
      }
    }
    .scene{
      .main{
        width: 1152px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        >div{
          width: 383px;
          height: 188px;
          background-color: #fff;
          display: flex;
          justify-content: space-around;
          align-items: center;
          p{
            color: #A6B3D5;
            font-size: 12px;
            margin-bottom: 16px;
          }
          div{
            font-size: 18px;
            font-weight: 400;
            color: #040513;
            line-height: 25px;
          }
          span{
            cursor: pointer;
            font-size: 18px;
            font-weight: 500;
            color: #1A65FC;
            line-height: 26px;
          }
        }
        >div:nth-child(1){
          box-shadow: 0px 6px 20px 0px rgba(8,78,253,0.06);
          backdrop-filter: blur(0px);
        }
        >div:nth-child(5){
          box-shadow: 0px 6px 20px 0px rgba(8,78,253,0.06);
          backdrop-filter: blur(0px);
        }
        >div:nth-child(9){
          box-shadow: 0px 6px 20px 0px rgba(8,78,253,0.06);
          backdrop-filter: blur(0px);
        }
        img{
          width: 58px;
          margin-bottom: 5px;
        }
      }
    }
    .customerBox{
      background-color:#fff ;
      margin-bottom: 82px;
    }
    .customer{
      width: 1152px;
      margin: 0 auto;
      
      .customermain{
        
        .swiper-slide{
          width: 351px;
          height: 510px;
          transform: scale(0.87);
          transition: 300ms;
          >img{
            width:100%;
            height:100%
          }
        }
        .swiper-slide-active{
          transform: scale(1)
        }
      }
     
    }
}